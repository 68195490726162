import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useSnackbar } from 'notistack';
import deepEqual from 'deep-equal';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useGlobalState } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import AppContainer from '@components/AppContainer';
import { DEFAULT_STATE_KEY, LOGIN_TOKEN_KEY, LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH } from '@consts';
import initializeGraphqlClient from '@graphql/initialize';
import isNotLoggedInException from '@graphql/isNotLoggedInException';
import MutationTokenLogin from '@graphql/mutations/MutationTokenLogin';
import reAuthenticateUser from '@graphql/reAuthenticateUser';
import useOnlineStatus from '@utils/useOnlineStatus';

import appConfig from './appConfig';
import { STATE_LOGGED_OUT } from './state';

import de from './i18n/de.json';
import en from './i18n/en.json';
import useStickyState from './nam-react-core/utils/useStickyState';
import useErrorHandler from './utils/useErrorHandler';

i18n.load({
  de,
  en,
  nl: en,
});

const getRedirectPath = (path, rootPath = '/') => {
  const isLoginPath = [LOGIN_WITH_CREDENTIALS_PATH, LOGIN_WITH_TOKEN_PATH].includes(path);

  return !isLoginPath && path ? path : rootPath;
};

const App = () => {
  const state = useGlobalState();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const isOnline = useOnlineStatus();
  const clientRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isInitialized, setInitialized] = useState(false);
  const [errors, setErrors] = useState([]);
  const [previousPath, setPreviousPath] = useStickyState('App.previousPath');

  const currentPathname = location.pathname;
  const redirectPath = getRedirectPath(previousPath);

  const { updateGlobalState, user } = state;

  useErrorHandler({
    errors,
    isOnline,
    setErrors,
  });

  /**
   *  EVENT HANDLER
   */

  const handleRedirect = useCallback(
    (pathname) => {
      if (pathname) {
        history.push({ pathname });
        setPreviousPath(currentPathname);
      }
    },
    [currentPathname, history, setPreviousPath]
  );

  const handleLogin = useCallback(
    (login, customRedirectPath) => {
      const { loginToken, status: loginStatus, user: loginUser } = login;

      if (loginStatus === 'NOT_LOGGED_IN' && !loginUser) {
        enqueueSnackbar(i18n.t('notification.loginError'), {
          key: 'loginError',
          preventDuplicate: true,
          variant: 'error',
        });
      }

      if (loginStatus === 'SECOND_FACTOR_REQUIRED') {
        updateGlobalState({ twoFactorPending: true, loginStatus });
      }

      if (loginStatus === 'LOGGED_IN') {
        const newRedirectPath = getRedirectPath(customRedirectPath || previousPath);
        window.localStorage.setItem(LOGIN_TOKEN_KEY, loginToken);

        const userId = loginUser.id;
        updateGlobalState({
          loginStatus,
          user: loginUser,
          lastLogin: {
            ...(state?.lastLogin || {}),
            [userId]: +new Date(),
          },
        });
        handleRedirect(newRedirectPath);
      }
    },
    [enqueueSnackbar, handleRedirect, previousPath, state, updateGlobalState]
  );

  const handleLogout = useCallback(async () => {
    const client = clientRef.current;

    handleRedirect(LOGIN_WITH_CREDENTIALS_PATH);
    updateGlobalState(STATE_LOGGED_OUT);
    window.localStorage.removeItem(LOGIN_TOKEN_KEY);
    await client.stop();
    await client.resetStore();
  }, [handleRedirect, updateGlobalState]);

  const handleReLogin = async () => {
    const client = clientRef.current;
    const token = window.localStorage.getItem(LOGIN_TOKEN_KEY) || '';
    const { data } = await client.mutate({ mutation: MutationTokenLogin, variables: { token } });
    const loginStatus = data?.tokenLogin.status;
    const loginUser = data?.tokenLogin.user;

    if (loginUser && loginStatus === 'LOGGED_IN') {
      updateGlobalState({ loginStatus, user: loginUser });
    } else {
      handleLogout();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errorLink = useCallback(
    onError(({ graphQLErrors, networkError, operation, forward, response }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
        );

        if (isNotLoggedInException(graphQLErrors)) {
          return reAuthenticateUser({
            promise: handleReLogin,
            operation,
            forward,
            onCatchError: handleLogout,
          });
        } else {
          setErrors(graphQLErrors);
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        setErrors([networkError]);
      }
    }),
    []
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const initialize = async () => {
      const _client = await initializeGraphqlClient(errorLink);
      clientRef.current = _client;
      setInitialized(true);
    };

    initialize();
  }, [errorLink]);

  useEffect(() => {
    const execute = (event) => {
      if (event.key === DEFAULT_STATE_KEY) {
        const { loginStatus, user: storedUser } = JSON.parse(event.newValue) || {};
        updateGlobalState({ loginStatus, user: storedUser, isSSODisabled: true });

        !storedUser ? handleLogout() : !deepEqual(user, storedUser) && handleRedirect(redirectPath);
      }
    };

    window.addEventListener('storage', execute);

    return () => {
      window.removeEventListener('storage', execute);
    };
  }, [handleLogout, handleRedirect, redirectPath, updateGlobalState, user]);

  /**
   *  APP STATE
   */

  const appState = {
    ...state,
    history,
    isMobile,
    isOnline,
    location,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onRedirect: handleRedirect,
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <ApolloProvider client={clientRef.current}>
      <AppContainer
        appProps={{ viewCode: window.VIEW_CODE, worldCode: window.WORLD_CODE }}
        config={appConfig}
        state={appState}
      />
    </ApolloProvider>
  );
};

export default App;
