import { FORM_COMPONENT_CHILDREN_KEY, FORM_ELEMENT_ENUM } from '../consts';

const flattenDeepByKeyWithoutOmitted = (array, key = FORM_COMPONENT_CHILDREN_KEY) => {
  return array.reduce((prev, curr) => {
    if (!curr.omitted) {
      prev.push(curr);
      curr[key] && prev.push(...flattenDeepByKeyWithoutOmitted(curr[key], key));
    }
    return prev;
  }, []);
};

const hasChildrenWithDefectStatus = (forms = []) => {
  return flattenDeepByKeyWithoutOmitted(forms)
    .filter(({ typename }) => typename === FORM_ELEMENT_ENUM)
    .some(({ statusValue }) => statusValue?.isDefectStatus);
};

export default hasChildrenWithDefectStatus;
