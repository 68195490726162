import addToCache from './addToCache';
import getTileUrl from './getTileUrl';
import getVectorTileUrls from './getVectorTileUrls';
import handleFetchBaseTiles from './handleFetchBaseTiles';
import { BASE_KEY, MAX_TILE_ZOOM_LEVEL, MIN_TILE_ZOOM_LEVEL } from '../consts';

const getConcatFeatures = (featureCollections) =>
  featureCollections.reduce((pre, cur) => {
    const { features } = cur;
    pre.push(...features);
    return pre;
  }, []);

const useOfflineTiles = (params) => {
  const { mapProps, adminUnit } = params;
  const { srid, vectorTileLayers = [], vectorTileServerUrl } = mapProps;

  const collections = adminUnit?.featureCollections;
  const features = getConcatFeatures(collections);
  const offlineMapId = `${BASE_KEY}_${adminUnit.id}`;

  const addLayerId = async () => {
    // get url of style and data json and pushes it to the Cache Storage
    const tileUrl = await getTileUrl(vectorTileServerUrl);

    if (tileUrl) {
      // Get Base Tiles
      const urls = getVectorTileUrls({
        features,
        minZoomLevel: MIN_TILE_ZOOM_LEVEL,
        maxZoomLevel: MAX_TILE_ZOOM_LEVEL,
        url: tileUrl,
      });

      await handleFetchBaseTiles(offlineMapId, tileUrl, srid);
      await addToCache(offlineMapId, urls);
    }

    for (let i = 0; i < vectorTileLayers.length; i++) {
      const { id, sourceOptions } = vectorTileLayers[i];
      const mapId = `${offlineMapId}_${id}`;
      const url = sourceOptions?.url;

      if (url) {
        const urls = getVectorTileUrls({
          features,
          minZoomLevel: MIN_TILE_ZOOM_LEVEL,
          maxZoomLevel: MAX_TILE_ZOOM_LEVEL,
          url,
        });

        await handleFetchBaseTiles(mapId, url, srid);
        await addToCache(mapId, urls);
      }
    }
  };

  const deleteLayerId = async () => {
    await window.caches.delete(offlineMapId);

    vectorTileLayers.forEach(async (vectorTileLayer) => {
      const { id } = vectorTileLayer;
      const mapId = `${offlineMapId}_${id}`;
      await window.caches.delete(mapId);
    });
  };

  const hasIdInCache = async () => {
    return await window.caches.has(offlineMapId);
  };

  return {
    addLayerId,
    deleteLayerId,
    hasIdInCache,
  };
};

export default useOfflineTiles;
