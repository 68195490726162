import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Trigger } from '@geomagic/core';

import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '../../consts';

const MAX_STEPS_FOR_DOTS = 5;

const Carousel = (props) => {
  const {
    activeStep: externalActiveStep = null,
    children,
    loop = true,
    isTextButton = false,
    setActiveStep: setExternalActiveStep,
    withPadding = true,
  } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop });
  const [activeStep, setActiveStep] = useState(null);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const maxSteps = children.length;

  const iconProps = { color: 'inherit', fontSize: 'large' };

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
    setActiveStep(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    onDotButtonClick(externalActiveStep);
  }, [externalActiveStep, onDotButtonClick]);

  useEffect(() => {
    if (setExternalActiveStep) {
      setExternalActiveStep(activeStep);
    }
    if (activeStep >= maxSteps) {
      onDotButtonClick(0);
    }
  }, [activeStep, setExternalActiveStep, onDotButtonClick, maxSteps]);

  const BackButton = isTextButton ? (
    <Trigger disabled={prevBtnDisabled} onClick={onPrevButtonClick} {...PRIMARY_TRIGGER_PROPS}>
      <ChevronLeftIcon />
      {i18n.t('button.back')}
    </Trigger>
  ) : (
    <Trigger disabled={prevBtnDisabled} icon={<ChevronLeftIcon {...iconProps} />} onClick={onPrevButtonClick} />
  );

  const NextButton = isTextButton ? (
    <Trigger disabled={nextBtnDisabled} onClick={onNextButtonClick} {...PRIMARY_TRIGGER_PROPS}>
      {i18n.t('button.next')}
      <ChevronRightIcon />
    </Trigger>
  ) : (
    <Trigger disabled={nextBtnDisabled} icon={<ChevronRightIcon {...iconProps} />} onClick={onNextButtonClick} />
  );

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(withPadding && { padding: 2 }),
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flex: 1,
        }}
        ref={emblaRef}
      >
        <Box
          sx={{
            backfaceVisibility: 'hidden',
            display: 'flex',
            flex: 1,
            touchAction: 'pan-y pinch-zoom',
            width: '100%',
          }}
        >
          {children.map((child, index) => (
            <Box key={index} sx={{ flex: '0 0 100%', minWidth: 0 }}>
              {child}
            </Box>
          ))}
        </Box>
      </Box>
      {children.length > 1 && (
        <MobileStepper
          activeStep={activeStep}
          backButton={BackButton}
          nextButton={NextButton}
          position="static"
          steps={maxSteps}
          sx={{ bgcolor: 'transparent' }}
          variant={maxSteps <= MAX_STEPS_FOR_DOTS ? 'dots' : 'text'}
        />
      )}
    </Box>
  );
};

Carousel.propTypes = {
  activeStep: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  loop: PropTypes.bool,
  isTextButton: PropTypes.bool,
  setActiveStep: PropTypes.func,
  withPadding: PropTypes.bool,
};

export default Carousel;
