import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { Trigger } from '@geomagic/core';
import { getEntityClass, getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import AccordionItem from '@components/AccordionItem';
import Dispatch from '@components/Dispatch';
import AddDraftTrigger from '@components/Dispatch/AddDraftTrigger';
import Placeholder from '@components/Placeholder';
import { CREATE_DISPATCHES_KEY } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import { CLASSNAME_DISPATCH } from '@graphql/consts';
import getDatabaseDocRelations from './utils/getDatabaseDocRelations';
import EditFunclocTriggers from '../Funcloc/EditFunclocTriggers';
import IconFormProgress from './IconFormProgress';

const TRIGGER_SX = {
  pointerEvents: 'auto',
  whiteSpace: 'noWrap',
};

const FormElementBlock = (props) => {
  const {
    childRef,
    children,
    context,
    data,
    doc: assignmentDoc,
    expanded,
    infoTrigger,
    onEditNewFuncloc,
    onRemoveFuncloc,
    onSelect,
    parentPath,
    path,
    ...accordionProps
  } = props;

  const itemRef = useRef(null);
  const {
    allRelevantDispatches = [],
    client,
    entityClasses,
    getPreviousMap,
    isMobile,
    isOnline,
    isReadOnly,
    mapProps,
    onUpdateRelevantDispatches,
    onUpdate,
    user,
  } = context;

  const {
    description,
    dispatchesCreatable,
    dispatchCreationConfig,
    fromTemplate = false,
    id,
    name,
    omitted,
    optional,
    relevantDispatches = [],
  } = data;

  const assignment = assignmentDoc.getPatchedEntity();
  const hasDispatchCreationConfig = !!dispatchCreationConfig;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [, setCreateDispatchesMode] = useStickySessionState(CREATE_DISPATCHES_KEY);

  const isExpanded = expanded && !omitted;
  const disabled = children?.length < 1 || omitted;
  const docRelations = getDatabaseDocRelations(assignmentDoc, data, path);
  const entityClassDispatch = getEntityClass(entityClasses, CLASSNAME_DISPATCH);
  const userId = user.id;

  const mergedRelevantDispatches = [
    ...relevantDispatches,
    ...allRelevantDispatches.filter((dispatch) => dispatch?.blockId === id).map((item) => getReference(item)),
  ];

  const hasRelevantDispatches = mergedRelevantDispatches.length > 0;

  const dispatchListLabel = i18n.t('button.dispatches', {
    variables: { amount: mergedRelevantDispatches?.length || '0' },
  });

  /**
   *  DATABASE
   */

  const queryProps = {
    [ENTITY_SELECTOR_KEY]: { $in: mergedRelevantDispatches.map(({ id: dispatchId }) => dispatchId) },
  };

  /**
   *  EVENT HANDLER
   */

  const handleAddedDraft = async (createdDispatch) => {
    const newDispatch = getReference(createdDispatch);

    await onUpdateRelevantDispatches({ newDispatch, blockId: id });
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleOpenModal = (event) => {
    event.stopPropagation();

    setDialogOpen(true);
  };

  const handleDispatchCreationMode = () => {
    setCreateDispatchesMode({
      assignmentId: assignment.id,
      dispatchCreationConfig,
      formElementBlockId: id,
      path,
      docRelations,
    });
  };

  const handleEditBlock = () => {
    onEditNewFuncloc({
      parentPath,
      path,
    });
  };

  const handleRemoveBlock = () => {
    onRemoveFuncloc({ path, removeId: id });
  };

  const handleOmit = (event) => {
    event.stopPropagation();
    onUpdate({ omitted: !omitted }, path, data);
  };

  /**
   *  COMPONENTS
   */

  const DispatchTriggerComponent =
    dispatchesCreatable || mergedRelevantDispatches?.length > 0 ? (
      <ButtonGroup color="primary" disableElevation size="medium" variant="contained">
        <Button onClick={handleOpenModal} size="small" sx={TRIGGER_SX}>
          {dispatchListLabel}
        </Button>
        {dispatchesCreatable && !isReadOnly ? (
          hasDispatchCreationConfig ? (
            <Trigger
              color="primary"
              icon={<QueuePlayNextIcon />}
              onClick={handleDispatchCreationMode}
              sx={TRIGGER_SX}
              variant="contained"
            />
          ) : (
            <AddDraftTrigger
              docRelations={docRelations}
              entityClass={entityClassDispatch}
              entityClasses={entityClasses}
              getPreviousMap={getPreviousMap}
              isMobile={isMobile}
              mapProps={mapProps}
              onAddedDraft={handleAddedDraft}
              triggerSX={TRIGGER_SX}
              user={user}
              userId={userId}
              variant="contained"
            />
          )
        ) : null}
      </ButtonGroup>
    ) : null;

  const TitleComponent = (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Typography component="div" noWrap variant="h6">
        {name}
      </Typography>
      {infoTrigger &&
        React.cloneElement(infoTrigger, {
          sx: TRIGGER_SX,
        })}
      {fromTemplate && (
        <EditFunclocTriggers
          disableDelete={hasRelevantDispatches}
          onEditNewFuncloc={handleEditBlock}
          onDeleteFuncloc={handleRemoveBlock}
        />
      )}
    </Box>
  );

  const DialogContent = (
    <Dispatch
      areMapActionsDisabled={true}
      client={client}
      dispatchCreationConfig={dispatchCreationConfig}
      docRelations={docRelations}
      entityClasses={entityClasses}
      isDetail={true}
      isDocumentsHidden={false}
      isGrouped={true}
      isMobile={isMobile}
      isOnline={isOnline}
      isReadOnly={isReadOnly}
      listPlaceholderComponent={
        <Placeholder
          icon={<DescriptionIcon />}
          title={i18n.t('dispatch.placeholder.noDrafts.title')}
          subtitle={i18n.t('dispatch.placeholder.noDrafts.subtitle')}
        />
      }
      getPreviousMap={getPreviousMap}
      mapProps={mapProps}
      onAddedDraft={handleAddedDraft}
      queryProps={queryProps}
      user={user}
    />
  );

  return (
    <>
      <AccordionItem
        disabled={disabled}
        expanded={isExpanded}
        id={data.id}
        onSelect={onSelect}
        panelAction={DispatchTriggerComponent}
        panelIcon={
          <IconFormProgress
            forms={[data]}
            onClick={optional && !isReadOnly ? handleOmit : null}
            omitted={omitted}
            optional={optional}
          />
        }
        ref={itemRef}
        title={TitleComponent}
        {...(description && !infoTrigger && { subtitle: description })}
        {...accordionProps}
      >
        {children}
      </AccordionItem>
      <StackedDialog
        content={DialogContent}
        isFullscreen={true}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={i18n.t('dispatches.dialog.title', { variables: { name } })}
      />
    </>
  );
};

FormElementBlock.propTypes = {
  childRef: PropTypes.object,
  children: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object,
  expanded: PropTypes.bool,
  infoTrigger: PropTypes.node,
  onEditNewFuncloc: PropTypes.func,
  onRemoveFuncloc: PropTypes.func,
  onSelect: PropTypes.func,
  parentPath: PropTypes.string,
  path: PropTypes.string,
};

export default FormElementBlock;
