const getCacheSize = async (cacheNames = []) => {
  // Note: opaque (i.e. cross-domain, without CORS) responses in the cache will return a size of 0.
  let cacheSize = 0;

  const sizePromises = cacheNames.map(async (cacheName) => {
    const hasCacheName = await window.caches.has(cacheName);

    if (hasCacheName) {
      const cache = await caches.open(cacheName);
      const keys = await cache.keys();

      await Promise.all(
        keys.map(async (key) => {
          const response = await cache.match(key);
          const blob = await response.blob();
          cacheSize += blob.size;
        })
      );
    }
  });

  await Promise.all(sizePromises);

  return cacheSize;
};

export default getCacheSize;
