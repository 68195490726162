import { i18n } from '@geomagic/i18n';

import GeneralPreferences from './modals/GeneralPreferences';
import MapPreferences from './modals/MapPreferences';
import OfflineCache from './modals/OfflineCache';

const getMenuItems = () => {
  return [
    {
      id: 'general',
      label: i18n.t('label.preference.general'),
      Component: GeneralPreferences,
    },
    {
      id: 'map',
      label: i18n.t('label.preference.map'),
      Component: MapPreferences,
    },
    {
      id: 'offline',
      label: i18n.t('label.preference.offline'),
      Component: OfflineCache,
    },
  ];
};

export default getMenuItems;
