import { i18n } from '@geomagic/i18n';

const fetchUrl = async (url) =>
  fetch(url)
    .then(async (response) => {
      let json;

      switch (response.status) {
        case 200:
          const clonedResponse = response.clone();

          if (clonedResponse.headers.get('content-type').includes('application/json')) {
            json = await clonedResponse.json();
          }

          return { json, response };
        case 404:
          console.error(`${response.status}: ${i18n.t('offlineMap.notification.fetchError')}`, `${url}`);
          break;
        default:
          console.error(`${response.status}: ${response.text && response.text()}`, `${url}`);
          break;
      }

      return { json: undefined, response: undefined };
    })
    .catch((e) => console.error('Connection error', e));

export default fetchUrl;
