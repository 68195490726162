import getMandatoryElements from './getMandatoryElements';
import isCompletedFormElement from './isCompletedFormElement';

const TOTAL_VALUE = 100;

const getFormProgress = (forms = []) => {
  const mandatoryElements = getMandatoryElements(forms);

  const progress = mandatoryElements.filter(isCompletedFormElement).length;
  const total = mandatoryElements.length;

  const normalizedValue = total === 0 ? 0 : (progress * TOTAL_VALUE) / total;

  const isSuccess = normalizedValue === TOTAL_VALUE;
  const isWarning = normalizedValue >= 0 && normalizedValue < TOTAL_VALUE;

  return { isSuccess, isWarning, normalizedValue, progress, total };
};

export default getFormProgress;
