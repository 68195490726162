import { DEFAULT_TEXT_FIELD_PROPS } from '@consts';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import DateTimeInput from '../../DateTimeInput';
import { DISABLED_IF_UNMET, HIDDEN_IF_UNMET } from './consts';
import getDateFormatObject from '../../../utils/getDateFormatObject';
import getEnumList from './getEnumList';
import getNumberFormatObject from '../../../utils/getNumberFormatObject';
import FractionInput from '../../FractionInput';
import isDependencyMet from './isDependencyMet';
import MultipleSelect from '../../../components/MultipleSelect';
import SingleEnumSelect from '../SingleEnumSelect';

/**
 * Get Autoform schema and ui by attribute type
 * @param {object} prop
 * @param {object} prop.attributeType
 * @param {object} prop.entity
 * @param {Array} prop.attributeTypes
 * @param {Array} prop.attributeValues
 * @param {boolean} prop.isReadOnly
 * @param {boolean} prop.isMobile
 * @param {boolean} prop.isAlternativeEnumSelect
 * @returns {object}
 */
const getAutoFormPropsByAttributeType = ({
  attributeType,
  entity = {},
  attributeTypes = [],
  attributeValues = [],
  isReadOnly = false,
  isMobile = true,
  isAlternativeEnumSelect = false,
}) => {
  const { allowedValues, dataType, dependency, mandatory, multiSelect = false, options, readOnly } = attributeType;

  let enumList;
  let fieldUI = {};
  let type;
  let schemaProps = {};
  let isDisabled = readOnly || isReadOnly;
  let isHidden = false;

  switch (dataType) {
    case 'STRING':
    case 'LINK':
    case 'EMAIL':
      const { rows, regex, minLength, maxLength } = options;
      type = 'string';
      if (rows && rows > 1) {
        fieldUI.rows = rows;
        fieldUI.multiline = true;
      }
      fieldUI = {
        ...fieldUI,
        ...DEFAULT_TEXT_FIELD_PROPS,
      };
      schemaProps = {
        ...(regex && { pattern: regex }),
        ...(minLength && { minLength }),
        ...(maxLength && { maxLength }),
      };
      break;
    case 'FRACTION':
      if (!enumList) {
        fieldUI = {
          isDisabled: readOnly,
          CustomFormField: FractionInput,
          enumList,
          isRequired: mandatory,
          ...getNumberFormatObject(attributeType),
        };
      }
      type = ['number', 'null'];
      break;
    case 'INT':
    case 'FLOAT':
    case 'CURRENCY':
      if (!enumList) {
        const { minValue, maxValue } = options;
        fieldUI = {
          ...fieldUI,
          ...getNumberFormatObject(attributeType),
          isFormatted: true,
          ...DEFAULT_TEXT_FIELD_PROPS,
        };
        schemaProps = {
          ...(minValue && { minimum: minValue }),
          ...(maxValue && { maximum: maxValue }),
        };
      }
      type = 'number';
      break;
    case 'BOOLEAN':
      type = 'boolean';
      break;
    case 'DATE':
    case 'TIMESTAMP':
      const { minRelDays, maxRelDays } = options;
      const createdOn = entity?.auditInfo?.createdOn;
      fieldUI = {
        attributeType,
        CustomFormField: DateTimeInput,
        dateFormatObject: getDateFormatObject(attributeType, entity),
        isMobile,
      };
      schemaProps = {
        ...(minRelDays && {
          minimum: createdOn ? dayjs(createdOn).add(minRelDays, 'd').valueOf() : dayjs().add(minRelDays, 'd').valueOf(),
        }),
        ...(maxRelDays && {
          maximum: createdOn ? dayjs(createdOn).add(maxRelDays, 'd').valueOf() : dayjs().add(maxRelDays, 'd').valueOf(),
        }),
      };
      type = ['number', 'null'];
      break;
    default:
      break;
  }

  if (dependency) {
    const { visibility } = dependency;
    const dependencyIsUnmet = !isDependencyMet(dependency, attributeTypes, attributeValues);
    if (dependencyIsUnmet && visibility === HIDDEN_IF_UNMET) {
      isHidden = true;
    }
    if (dependencyIsUnmet && visibility === DISABLED_IF_UNMET) {
      isDisabled = true;
    }
  }

  if (allowedValues && !!allowedValues.length) {
    if (multiSelect) {
      enumList = getEnumList(allowedValues, attributeType, true, attributeValues);
      fieldUI = {
        CustomFormField: MultipleSelect,
        enumList,
        required: mandatory,
      };
    } else {
      enumList = getEnumList(allowedValues, attributeType, false, attributeValues);
      fieldUI = {
        attributeType,
        CustomFormField: SingleEnumSelect,
        enumList,
        isAlternativeEnumSelect,
      };
    }
  }

  if (isDisabled) {
    fieldUI.disabled = true;
  }

  if (isHidden) {
    fieldUI.isHidden = true;
  }

  fieldUI.margin = 'dense';
  fieldUI.identifier = attributeType.id;
  fieldUI.grouping = attributeType.grouping;

  return {
    fieldSchema: {
      type,
      title: attributeType.name,
      ...(!isEmpty(enumList) && { enum: enumList }),
      ...schemaProps,
    },
    fieldUI,
  };
};

export default getAutoFormPropsByAttributeType;
