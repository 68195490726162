import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import getFormProgress from './utils/getFormProgress';

const VARIANT_LINEAR_SIZE = 4;

const getBackgroungGrey = ({ palette }) => palette.grey[palette.mode === 'light' ? 300 : 500];

const Progress = (props) => {
  const { forms = [] } = props;

  const { isSuccess, normalizedValue, total } = getFormProgress(forms);

  if (total < 1) return null;

  return (
    <Box
      sx={{ alignItems: 'center', display: 'flex', position: 'relative', height: VARIANT_LINEAR_SIZE, width: '100%' }}
    >
      <LinearProgress
        sx={{
          backgroundColor: getBackgroungGrey,
          flex: 1,
          '& .MuiLinearProgress-bar': {
            backgroundColor: isSuccess ? 'success.main' : 'warning.main',
          },
        }}
        value={normalizedValue}
        variant="determinate"
      />
    </Box>
  );
};

Progress.propTypes = {
  forms: PropTypes.array,
};

export default Progress;
