import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { i18n } from '@geomagic/i18n';

import OfflineSectionListItem from './OfflineSectionListItem';

const OfflineMap = (props) => {
  const { mapProps, admintUnits } = props;
  const { values } = admintUnits || {};

  return (
    <>
      <ListItem disableGutters>
        <ListItemText
          primary={i18n.t('label.preference.offlineMap.title')}
          secondary={i18n.t('label.preference.offlineMap.subtitle')}
        />
      </ListItem>
      <Divider />
      {values?.map((item) => (
        <>
          <ListItem key={item.id} disableGutters>
            <OfflineSectionListItem data={item} mapProps={mapProps} />
          </ListItem>
          <Divider />
        </>
      ))}
    </>
  );
};

OfflineMap.propTypes = {
  mapProps: PropTypes.object.isRequired,
  admintUnits: PropTypes.object,
};

export default OfflineMap;
