import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Switch from '@mui/material/Switch';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { i18n } from '@geomagic/i18n';

import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useOfflineTiles from './utils/useOfflineTiles';
import getCacheSize from './utils/getCacheSize';
import formatBytes from './utils/formatBytes';
import { BASE_KEY } from './consts';

const OfflineSectionListItem = (props) => {
  const { data, mapProps } = props;
  const displayName = data.displayName;
  const { addLayerId, deleteLayerId, hasIdInCache } = useOfflineTiles({ adminUnit: data, mapProps });
  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const [checked, setChecked] = useState(false);
  const [cacheSize, setCacheSize] = useState(0);

  const handleOnSwitch = async (_event, newChecked) => {
    const execute = newChecked ? addLayerId : deleteLayerId;

    await enqueueLoadingSnackbar({
      loadingText: newChecked
        ? i18n.t('offlineMap.notification.loadingCreate')
        : i18n.t('offlineMap.notification.loadingDelete'),
      finishedText: newChecked ? i18n.t('offlineMap.notification.created') : i18n.t('offlineMap.notification.deleted'),
      func: execute,
      preventDuplicate: true,
      finishedVariant: 'success',
    });

    setChecked(newChecked);
  };

  useEffect(() => {
    const hasCaches = async () => {
      const result = await hasIdInCache();

      setChecked(result);
    };

    hasCaches();
  }, [hasIdInCache]);

  useEffect(() => {
    const setCacheSizeNew = async () => {
      const { vectorTileLayers = [] } = mapProps;
      const offlineMapId = `${BASE_KEY}_${data.id}`;

      const Ids = [offlineMapId];

      vectorTileLayers.forEach((vectorTileLayer) => {
        const { id } = vectorTileLayer;
        const mapId = `${offlineMapId}_${id}`;
        Ids.push(mapId);
      });

      const size = await getCacheSize(Ids);

      setCacheSize(size);
    };

    setCacheSizeNew();
  }, [data.id, mapProps, checked, addLayerId, deleteLayerId]);

  return (
    <>
      <ListItemText primary={displayName} secondary={formatBytes(cacheSize)} />
      <ListItemSecondaryAction>
        <Switch checked={checked} color="primary" onChange={handleOnSwitch} />
      </ListItemSecondaryAction>
    </>
  );
};

OfflineSectionListItem.propTypes = {
  data: PropTypes.object.isRequired,
  mapProps: PropTypes.object.isRequired,
};

export default OfflineSectionListItem;
