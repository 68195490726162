import fetchUrl from './fetchUrl';

/**
 * Add urls to cache storage
 * @param {string} key - Cache key
 * @param {Array.<string>} urls - Urls to cache
 * @returns {void}
 */
const addToCache = async (key, urls = []) => {
  const cache = await window.caches.open(key);

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];

    const { response } = await fetchUrl(url);

    if (response && response?.status === 200) {
      await cache.put(url, response);
    }
  }
};

export default addToCache;
