import { useQuery } from '@apollo/client';

import { VIEW_CODE } from '@components/Map/consts';
import QueryEntityClasses from '@graphql/queries/QueryEntityClasses';
import QuerySSOSettings from '@graphql/queries/QuerySSOSettings';
import QuerySystemInformation from '@graphql/queries/QuerySystemInformation';
import QueryWorld from '@graphql/queries/QueryWorld';
import QueryAdmitUnits from '@graphql/queries/QueryAdmitUnits';
import {
  CLASSNAME_ASSIGNMENT,
  CLASSNAME_ADMINUNIT,
  CLASSNAME_DISPATCH,
  CLASSNAME_DOCUMENT,
  CLASSNAME_FOLDER,
  CLASSNAME_FUNCLOC,
  EMPTY_FILTER_CONFIG,
} from '@graphql/consts';

const DEFAULT_ENTITY_CLASS_NAMES = [
  CLASSNAME_ASSIGNMENT,
  CLASSNAME_DISPATCH,
  CLASSNAME_FUNCLOC,
  CLASSNAME_DOCUMENT,
  CLASSNAME_FOLDER,
  CLASSNAME_ADMINUNIT,
];

const useGeneralQueries = ({ appProps, isOnline, user }) => {
  /**
   *  QUERY CONFIG
   */

  const queryConfigEntityClasses = {
    skip: isOnline && !user,
    variables: {
      classNames: DEFAULT_ENTITY_CLASS_NAMES,
    },
  };

  const queryConfigSSOSettings = {
    fetchPolicy: 'network-only',
  };

  const queryConfigSystemInformation = {
    skip: isOnline && !user,
  };

  const queryConfigWorld = {
    skip: isOnline && !user,
    variables: {
      viewCodes: [appProps?.viewCode || VIEW_CODE],
    },
  };

  const queryAdminUnits = {
    skip: isOnline && !user,
    variables: {
      filter: {
        ...EMPTY_FILTER_CONFIG,
        entityClassNames: [CLASSNAME_ADMINUNIT],
      },
    },
  };

  /**
   *  QUERIES
   */

  const { data: dataEntityClasses, loading: loadingEntityClasses } = useQuery(
    QueryEntityClasses,
    queryConfigEntityClasses
  );

  const { data: dataSSOSettings, loading: loadingSSOSettings } = useQuery(QuerySSOSettings, queryConfigSSOSettings);

  const { data: dataSystemInformation, loading: loadingSystemInformation } = useQuery(
    QuerySystemInformation,
    queryConfigSystemInformation
  );

  const { data: dataWorld, loading: loadingWorld } = useQuery(QueryWorld, queryConfigWorld);

  const { data: admintUnits, loading: loadingOfflineMap } = useQuery(QueryAdmitUnits, queryAdminUnits);

  return {
    entityClasses: dataEntityClasses?.entityClasses,
    admintUnits: admintUnits?.entities,
    ssoSettings: dataSSOSettings?.systemInformation?.ssoSettings,
    systemInformation: dataSystemInformation?.systemInformation,
    worlds: dataWorld?.worlds,
    loadingEntityClasses,
    loadingOfflineMap,
    loadingSSOSettings,
    loadingSystemInformation,
    loadingWorld,
  };
};

export default useGeneralQueries;
