import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import TaskIcon from '@mui/icons-material/AssignmentOutlined';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import Accordion from '@components/Accordion';
import Placeholder from '@components/Placeholder';
import PageSelect from './PageSelect';
import PageTrigger from './PageTrigger';
import LinearFormProgress from './LinearFormProgress';

const scrollToPosition = ({ scrollRef, itemRef, behavior = 'smooth', isMobile }) => {
  const scrollNode = scrollRef?.current;
  const itemNode = itemRef?.current;
  const { offsetTop } = scrollNode || {};

  setTimeout(() => {
    if (itemNode) {
      const scrollTo = itemNode.offsetTop - offsetTop;
      if (isMobile) {
        scrollNode && scrollNode.scrollTo({ top: scrollTo, behavior });
      } else {
        scrollNode.scrollTop = scrollTo;
      }
    }
  }, 200);
};

const FormElementPage = (props) => {
  const {
    children,
    context,
    data,
    disableBack,
    disableForward,
    doc,
    entityClasses,
    forms,
    infoTrigger,
    onChangeMenu,
    onClickBack,
    onClickForward,
    onEditNewFuncloc,
    onRemoveFuncloc,
    path,
    templateForms,
  } = props;

  const scrollRef = useRef(null);

  const { isMobile, isReadOnly, onUpdateForm } = context;
  const { id: formId, omitted } = data;

  const entity = doc.getPatchedEntity();
  const selectedIndex = forms.findIndex(({ id }) => id === formId);
  const isMultiForm = forms && forms.length > 1;
  const isEmptyForm = forms.length === 0;

  const [activePanel, setActivePanel] = useState();

  /**
   *  EVENT HANDLER
   */

  const handleChangeSelect = (event, newValue) => {
    onChangeMenu(event, newValue);
  };

  const handleSelectPanel = useCallback(
    (event, isExpanded, panelId, itemRef) => {
      event.stopPropagation();
      setActivePanel(panelId);
      isExpanded && scrollToPosition({ scrollRef, itemRef, isMobile });
    },
    [isMobile]
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (children && children.length > 0) {
      setActivePanel(children[0].props.id);
    } else {
      setActivePanel(null);
    }
    // Opens first block, only change on form change
    /* eslint-disable-next-line */
  }, [formId]);

  return (
    <Fragment>
      {!isEmptyForm && (
        <Toolbar
          disableGutters
          sx={{
            alignItems: 'flex-start',
            backgroundColor: 'background.paper',
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column-reverse' },
          }}
        >
          <Box sx={{ width: '100%' }}>
            <LinearFormProgress forms={[data]} />
          </Box>
          <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, overflow: 'hidden', width: '100%' }}>
            {isMultiForm && <PageTrigger disabled={disableBack} onClick={onClickBack} variant="back" />}
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                py: 0,
                px: 1,
                marginBottom: 0.5,
                overflow: 'hidden',
              }}
            >
              <FormControl
                sx={{
                  overflow: 'hidden',
                  margin: 0,
                  marginTop: 0.5,
                }}
              >
                <Box sx={{ alignItems: 'center', display: 'flex', marginLeft: 0.5, marginRight: 0.5 }}>
                  <PageSelect
                    entityClasses={entityClasses}
                    forms={forms}
                    isMarkdown={!!infoTrigger}
                    isReadOnly={isReadOnly}
                    onChange={handleChangeSelect}
                    onEditNewFuncloc={onEditNewFuncloc}
                    onRemoveFuncloc={onRemoveFuncloc}
                    onUpdateForm={onUpdateForm}
                    path={path}
                    secondaryAction={infoTrigger}
                    templateForms={templateForms}
                    title={entity?.displayName}
                    value={selectedIndex}
                  />
                </Box>
              </FormControl>
            </Box>
            {isMultiForm && <PageTrigger disabled={disableForward} onClick={onClickForward} variant="forward" />}
          </Box>
        </Toolbar>
      )}
      {children?.length > 0 && !omitted ? (
        <ContentRoot
          ref={scrollRef}
          scrollable
          scrollbarProps={{
            containerRef: (ref) => {
              scrollRef.current = ref;
            },
          }}
          withPadding={false}
          withCustomScrollbar={!isMobile}
        >
          <Accordion selectedId={activePanel} onSelect={handleSelectPanel}>
            {children}
          </Accordion>
        </ContentRoot>
      ) : (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
          {!omitted && (
            <Placeholder
              icon={<TaskIcon />}
              title={i18n.t('assignment.placeholder.noCheckpoints.title')}
              subtitle={i18n.t('assignment.placeholder.noCheckpoints.subtitle')}
            />
          )}
        </Box>
      )}
    </Fragment>
  );
};

FormElementPage.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object,
  disableBack: PropTypes.bool,
  disableForward: PropTypes.bool,
  doc: PropTypes.object,
  entityClasses: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  infoTrigger: PropTypes.node,
  isMobile: PropTypes.bool,
  onChangeMenu: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickForward: PropTypes.func.isRequired,
  onEditNewFuncloc: PropTypes.func.isRequired,
  onRemoveFuncloc: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  templateForms: PropTypes.array.isRequired,
};

export default FormElementPage;
