import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const ICON_SIZE = 150;
const ICON_SIZE_XS = 100;

const animation = {
  animation: `0.5s linear fade-in, 0.5s ease-in 1.5s fade-out`,

  '@keyframes fade-in': {
    '0%': {
      opacity: 0,
      clipPath: 'circle(0)',
      filter: 'blur(5vmin)',
      borderRadius: '60% 40% 30% 20% / 30% 30% 70% 40%',
    },
    '20%': {
      borderRadius: '30% 60% 70% 40% / 50% 60% 30% 60%',
    },
    '80%': {
      borderRadius: 0,
      opacity: 1,
      filter: 'blur(1vmin)',
    },
    '100%': {
      clipPath: 'circle(100%)',
      filter: 'none',
    },
  },

  '@keyframes fade-out': {
    '0%': {
      opacity: 1,
      filter: 'none',
    },
    '100%': {
      filter: 'blur(1vmin)',
      opacity: 0,
      borderRadius: 0,
    },
  },
};

const Congratulations = (props) => {
  const { isAnimationVisible } = props;

  return (
    <Modal
      open={isAnimationVisible}
      aria-labelledby="modal-congratulations-title"
      aria-describedby="modal-congratulations-description"
      sx={{ zIndex: 1400 }}
    >
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 50% 50%, #66bb6a 0%, #66bbb7 100%)',
          },
          isAnimationVisible && animation,
        ]}
      >
        <DoneOutlineIcon
          sx={{
            fontSize: { xs: ICON_SIZE_XS, sm: ICON_SIZE },
          }}
        />
      </Box>
    </Modal>
  );
};

Congratulations.propTypes = {
  isAnimationVisible: PropTypes.bool.isRequired,
};

export default Congratulations;
