import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '@mui/material/List';
import { makeStyles } from '@geomagic/core';
import OfflineMap from '../OfflineMap/OfflineMap';

const useStyles = makeStyles()(({ spacing }) => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: spacing(),
    },
  };
});

const OfflineCache = (props) => {
  const { className } = props;
  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  return (
    <List className={classNames(classes.root, className)}>
      <OfflineMap {...props} />
    </List>
  );
};

OfflineCache.propTypes = {
  className: PropTypes.string,
  mapProps: PropTypes.object.isRequired,
};

export default OfflineCache;
