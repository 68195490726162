import gql from 'graphql-tag';

import FragmentFormElementBlock from '../fragments/FragmentFormElementBlock';
import FragmentFormFeatureCollections from '../fragments/FragmentFormFeatureCollections';

const MutationUpdateForm = gql`
  ${FragmentFormElementBlock}
  ${FragmentFormFeatureCollections}

  mutation MutationUpdateForm($form: FormInput!, $srid: Int, $worldId: NID) {
    updateForm(form: $form) {
      children {
        ...FormElementBlock
      }
      id
      name
      seqNumber
      omitted
      ...FormFeatureCollections
    }
  }
`;

export default MutationUpdateForm;
