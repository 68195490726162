import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import pointer from 'json-pointer';
import { useSnackbar } from 'notistack';
import { useRxDB, useRxDocument } from 'rxdb-hooks';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapIcon from '@mui/icons-material/Map';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { hideModal, Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { PRIMARY_TRIGGER_PROPS } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getPatch from '@database/getPatch';
import useShowPrompt from '@utils/useShowPrompt';
import { CLASSNAME_DISPATCH } from '@graphql/consts';
import DispatchDocuments from './DispatchDocuments';
import DispatchForm from './DispatchForm';
import DispatchMap from './DispatchMap';
import useMediaQuery from '@mui/material/useMediaQuery';
import getTasksAmount from '../Tasks/getTasksAmount';
import Checklist from '../Checklist';
import Journal from '../Journal';
import Tasks from '../Tasks';
import CongratulationsProvider from '../Tasks/Congratulations/CongratulationsProvider';

const getClosedElementPatch = ({ entity, path, updateId, update }) => {
  const items = pointer.get(entity, path);
  const updatedItems = items.map((item) => (item.id === updateId ? { ...item, ...update } : item));

  return {
    op: 'replace',
    path,
    value: updatedItems,
  };
};

const DispatchWizard = (props) => {
  const {
    entityClass,
    entityClasses,
    entityId,
    handleClose,
    isDocumentsHidden,
    isMobile,
    isOnline,
    mapProps,
    onSyncEntity,
    onUpdateEntity,
    previousMap,
    setDialogTitle,
    user,
  } = props;

  const database = useRxDB();
  const { result: dispatch } = useRxDocument('dispatches', entityId, {
    idAttribute: ENTITY_SELECTOR_KEY,
  });

  const entity = dispatch?.getPatchedEntity() || {};
  const entityType = entity?.entityType && getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const entityTypeName = entityType?.name;
  const entityTypes = entityClass?.entityTypes;

  const { documents = [] } = entity;

  const draftRef = useRef();
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);

  const draft = dispatch?.draft;
  const isDraft = !!draft;
  const isDraftClosed = !!draft?.closed;

  const counterDocuments = documents?.length || 0;
  const counterChecklist = entity?.checklistItems?.length || 0;
  const counterTasks = getTasksAmount(entity?.processInstances) || 0;
  const counterJournal = entity?.journal?.length || 0;
  const areChecklistItemsChecked = entity?.checklistItems?.every((item) => item.checked);

  const FormTab = useMemo(
    () => ({
      id: 'form',
      count: null,
      name: i18n.t('dispatch.label.tabGeneral'),
      component: DispatchForm,
      icon: <FormatListBulletedIcon />,
    }),
    []
  );

  const MapTab = useMemo(
    () => ({
      id: 'map',
      count: null,
      name: i18n.t('dispatch.label.tabMap'),
      component: DispatchMap,
      icon: <MapIcon />,
    }),
    []
  );

  const DocumentsTab = useMemo(
    () => ({
      id: 'documents',
      count: counterDocuments,
      name: i18n.t('dispatch.label.tabDocuments', {
        variables: {
          count: String(counterDocuments),
        },
      }),
      component: DispatchDocuments,
      icon: <AttachFileIcon />,
    }),
    [counterDocuments]
  );

  const TaskTab = useMemo(
    () => ({
      id: 'tasks',
      count: counterTasks,
      name: i18n.t('process.label.menuItem', {
        variables: {
          amount: String(counterTasks),
        },
      }),
      component: () => (
        <Box sx={{ overflow: 'auto', padding: '0.5 1', height: '100%' }}>
          <Tasks
            closeableErrorText={i18n.t('dispatch.description.checklistItemsNotChecked')}
            data={dispatch}
            entityClasses={entityClasses}
            isCloseable={areChecklistItemsChecked && isOnline}
            isMobile={isMobile}
            isOnline={isOnline}
            onSyncEntity={onSyncEntity}
            onUpdateEntity={onUpdateEntity}
            user={user}
          />
        </Box>
      ),
      icon: <AccountCircleIcon />,
    }),
    [
      areChecklistItemsChecked,
      counterTasks,
      dispatch,
      entityClasses,
      isMobile,
      isOnline,
      onSyncEntity,
      onUpdateEntity,
      user,
    ]
  );

  const ChecklistTab = useMemo(
    () => ({
      id: 'checklist',
      count: counterChecklist,
      name: i18n.t('checklist.label.menuItem', {
        variables: {
          amount: String(counterChecklist),
        },
      }),
      component: () => <Checklist data={dispatch} entityClasses={entityClasses} />,
      icon: <AssignmentTurnedInIcon />,
    }),
    [counterChecklist, dispatch, entityClasses]
  );

  const JournalTab = useMemo(
    () => ({
      id: 'journal',
      count: counterJournal,
      name: i18n.t('journal.label', {
        variables: {
          amount: String(counterJournal),
        },
      }),
      component: () => <Journal data={dispatch} user={user} />,
      icon: <MenuBookIcon />,
    }),
    [counterJournal, dispatch, user]
  );

  const TabComponents = useMemo(() => {
    return [
      FormTab,
      MapTab,
      ...(isDocumentsHidden ? [] : [DocumentsTab]),
      ...(isDraft ? [] : [TaskTab]),
      ChecklistTab,
      ...(isDraft ? [] : [JournalTab]),
    ];
  }, [ChecklistTab, DocumentsTab, FormTab, isDraft, isDocumentsHidden, MapTab, TaskTab, JournalTab]);

  const TabComponent = TabComponents[activeStep].component || null;

  /**
   *  GENERAL PROPS
   */

  const triggerProps = {
    size: 'medium',
  };

  /**
   *  EVENT HANDLER
   */

  const handleChangeTab = (event, newValue) => {
    const execute = () => setActiveStep(newValue);

    handleFormValidation(execute);
  };

  const handleFormValidation = (execute) => {
    const onValidateForm = draftRef?.current?.onValidateForm;
    const formStepIndex = TabComponents.findIndex(({ id }) => id === 'form');

    if (!isDraftClosed && onValidateForm && activeStep === formStepIndex) {
      onValidateForm()
        .then(() => {
          execute();
        })
        .catch((error) => console.log(error));
    } else {
      execute();
    }
  };

  const handlePromptCloseDraft = () => {
    const execute = () => {
      // Timeout is needed to notice type changes.
      setTimeout(() => {
        showPrompt({
          title: i18n.t('dispatch.dialog.closeDraft.title'),
          content: i18n.t('dispatch.dialog.closeDraft.content'),
          onOk: () => handleCloseDraft(dispatch),
        });
      }, 50);
    };

    handleFormValidation(execute);
  };

  const handleCloseDraft = async () => {
    const { uuid, relations } = dispatch;
    const update = { closed: true };

    if (relations) {
      const assignments = relations.filter(({ type }) => type === 'Assignment');

      for (let i = 0; i < assignments.length; i++) {
        const { id, path } = assignments[i];

        const collection = database.assignments;
        const selector = { [ENTITY_SELECTOR_KEY]: id };
        const assignmentDoc = await collection.findOne({ selector }).exec();
        const jsonPatch = assignmentDoc?.jsonPatch;
        const patchedEntity = assignmentDoc.getPatchedEntity();

        const newPatch = getClosedElementPatch({ entity: patchedEntity, path, updateId: uuid, update });

        await assignmentDoc.atomicUpdate((oldData) => {
          oldData.jsonPatch = getPatch(jsonPatch, newPatch);
          return oldData;
        });
      }
    }

    await dispatch.atomicUpdate((oldData) => {
      oldData.draft = { ...oldData.draft, closed: true };
      return oldData;
    });

    hideModal();
    handleClose();

    enqueueSnackbar(i18n.t('dispatch.notification.closedDraft'), {
      key: 'closedDraft',
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const handleUpdateDraft = async (newPatch) => {
    const jsonPatch = dispatch?.jsonPatch;

    await dispatch.atomicUpdate((oldData) => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (setDialogTitle && entityTypeName) {
      setDialogTitle(entityTypeName);
    }
  }, [entityTypeName, setDialogTitle]);

  /**
   *  COMPONENTS
   */

  const CloseComponent = (
    <div>
      {isDraft && !isDraftClosed && (
        <Trigger onClick={handlePromptCloseDraft} sx={{ marginLeft: 1 }} {...PRIMARY_TRIGGER_PROPS} {...triggerProps}>
          {i18n.t('dispatch.button.closeDraft')}
        </Trigger>
      )}
    </div>
  );

  const getStepComponent = () => {
    return (
      <TabComponent
        CloseComponent={CloseComponent}
        doc={dispatch}
        draftRef={draftRef}
        entityClass={entityClass}
        entityClasses={entityClasses}
        entityClassName={CLASSNAME_DISPATCH}
        entityTypes={entityTypes}
        isDraft={isDraft}
        isMobile={isMobile}
        mapProps={mapProps}
        onChange={handleUpdateDraft}
        onClose={handleCloseDraft}
        previousMap={previousMap}
        step={TabComponents[activeStep]}
        triggerProps={triggerProps}
      />
    );
  };

  const isMobileSM = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <CongratulationsProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', width: '100%' }}>
        <Paper square>
          <Tabs
            centered={!isMobileSM}
            onChange={handleChangeTab}
            scrollButtons={false}
            sx={{
              backgroundColor: 'background.default',
              borderBottom: 1,
              borderColor: 'divider',
            }}
            value={activeStep}
            variant={isMobileSM ? 'fullWidth' : 'standard'}
          >
            {TabComponents.map(({ id, name, icon, count }) => (
              <Tab
                key={id}
                sx={{ minWidth: 48, textTransform: 'capitalize' }}
                label={!isMobileSM && name}
                icon={
                  isMobileSM && count ? (
                    <Badge color="secondary" overlap="circular" variant="dot">
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )
                }
              />
            ))}
          </Tabs>
        </Paper>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {dispatch && entityTypes && getStepComponent()}
        </Box>
      </Box>
    </CongratulationsProvider>
  );
};

DispatchWizard.propTypes = {
  entityClass: PropTypes.object,
  entityClasses: PropTypes.array,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func.isRequired,
  isDocumentsHidden: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onSyncEntity: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  previousMap: PropTypes.object,
  setDialogTitle: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default DispatchWizard;
