import gql from 'graphql-tag';

// import FragmentEntity from '../fragments/FragmentEntity';
import FragmentFeatureCollections from '../fragments/FragmentFeatureCollections';

const QueryAdmitUnits = gql`
  ${FragmentFeatureCollections}

  query QueryEntities($filter: FilterInput!, $srid: Int, $worldId: NID) {
    entities(filter: $filter) {
      values {
        displayName
        id
        ...FeatureCollections
      }
      totalValueCount
    }
  }
`;

export default QueryAdmitUnits;
