import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const PANEL_ICON_WIDTH = 42;

const AccordionItem = React.forwardRef((props, ref) => {
  const { children, disabled, expanded, id, onSelect, panelAction, panelIcon, subtitle, title } = props;

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event, isExpanded) => {
    event.stopPropagation();
    onSelect && onSelect(event, isExpanded, id, ref);
  };

  const Summary = (
    <>
      {panelIcon && <Box sx={{ width: `${PANEL_ICON_WIDTH}px !important`, flexShrink: 0 }}>{panelIcon}</Box>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {typeof title === 'string' ? (
          <Typography variant="body2" component="div" noWrap>
            {title}
          </Typography>
        ) : (
          title
        )}
        <Typography sx={{ color: 'text.secondary', marginTop: 0.25 }} variant="body2" component="div" noWrap>
          {subtitle}
        </Typography>
      </Box>
      {panelAction && <Box sx={{ marginLeft: 'auto' }}>{panelAction}</Box>}
    </>
  );

  return (
    <Accordion
      ref={ref}
      disableGutters
      disabled={disabled}
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      square
      sx={{
        overflow: 'hidden',
        '&::before': {
          display: 'none',
        },
        '&:last-child': {
          borderBottom: 0,
        },
      }}
    >
      <AccordionSummary
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          '.MuiAccordionSummary-content': { alignItems: 'center', margin: `0px !important`, overflow: 'hidden' },
        }}
      >
        {Summary}
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>{children}</AccordionDetails>
    </Accordion>
  );
});

AccordionItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func,
  panelAction: PropTypes.element,
  panelIcon: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default AccordionItem;
