export const BASE_KEY = 'offlineMap';

// Extend of Vector Tiles
export const BASE_EXTENT = {
  epsgCode: 'EPSG:4326',
  extent: [5.98865807458, 47.3024876979, 15.0169958839, 54.983104153],
  // extent: [5.75, 47.19, 15.18, 55.14],
};

export const MIN_BASE_TILE_ZOOM_LEVEL = 0;
export const MAX_BASE_TILE_ZOOM_LEVEL = 6;
export const MIN_TILE_ZOOM_LEVEL = 7;
export const MAX_TILE_ZOOM_LEVEL = 14;
