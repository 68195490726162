import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import DispatchIcon from '@geomagic/nam-react-core/icons/Dispatch';
import hasChildrenWithDefectStatus from './utils/hasChildrenWithDefectStatus';
import getFormProgress from './utils/getFormProgress';

const AVATAR_ICON_SIZE = 20;
const DEFAULT_SIZE = 40;
const VARIANT_CIRCULAR_THICKNESS = 4;
const TOTAL_VALUE = 100;

const circularProps = {
  size: DEFAULT_SIZE,
  thickness: VARIANT_CIRCULAR_THICKNESS,
  variant: 'determinate',
};

const getBackgroungGrey = ({ palette }) => palette.grey[palette.mode === 'light' ? 300 : 500];

const CircularFormProgress = (props) => {
  const { forms = [] } = props;

  const hasDispatches = hasChildrenWithDefectStatus(forms);
  const { isSuccess, normalizedValue, total } = getFormProgress(forms);

  if (total < 1) return null;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        height: '100%',
        minHeight: DEFAULT_SIZE,
        marginRight: 2,
        textAlign: 'center',
      }}
    >
      <>
        <CircularProgress
          sx={{ color: getBackgroungGrey, position: 'absolute' }}
          value={TOTAL_VALUE}
          {...circularProps}
        />
        <CircularProgress
          sx={{
            color: getBackgroungGrey,
            position: 'absolute',
            '& .MuiCircularProgress-circle': {
              color: isSuccess ? 'success.main' : 'warning.main',
            },
          }}
          value={normalizedValue}
          {...circularProps}
        />
        <Typography
          sx={{ color: 'inherit', fontWeight: 600, letterSpacing: 0, marginTop: '1px', width: DEFAULT_SIZE }}
          variant="caption"
        >
          {total}
        </Typography>
      </>
      {hasDispatches && (
        <SvgIcon
          color="primary"
          sx={{
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'background.paper',
            borderRadius: '50%',
            position: 'absolute',
            height: `${AVATAR_ICON_SIZE}px`,
            width: `${AVATAR_ICON_SIZE}px`,
            bottom: (theme) => theme.spacing(-0.5),
            right: (theme) => theme.spacing(-0.5),
          }}
        >
          <DispatchIcon />
        </SvgIcon>
      )}
    </Box>
  );
};

CircularFormProgress.propTypes = {
  forms: PropTypes.array,
};

export default CircularFormProgress;
