import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

const useStyles = makeStyles()(({ spacing }) => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: spacing(),
    },
  };
});

const GeneralPreferences = (props) => {
  const { className, onUpdatePreferences, preferences: initialPreferences } = props;

  const [preferences, setPreferences] = useState(initialPreferences);

  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleChangeSwitch = (key) => (event) => {
    const newPreferences = { ...preferences, [key]: event.target.checked };
    setPreferences(newPreferences);
    onUpdatePreferences(newPreferences);
  };

  return (
    <List className={classNames(classes.root, className)}>
      <ListItem disableGutters>
        <ListItemText
          primary={i18n.t('label.preference.mapVisible.title')}
          secondary={i18n.t('label.preference.mapVisible.subtitle')}
        />
        <ListItemSecondaryAction>
          <Switch
            checked={preferences?.isMapVisible || false}
            color="primary"
            onChange={handleChangeSwitch('isMapVisible')}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem disableGutters>
        <ListItemText
          primary={i18n.t('label.preference.rotateViewByOrientation.title')}
          secondary={i18n.t('label.preference.rotateViewByOrientation.subtitle')}
        />
        <ListItemSecondaryAction>
          <Switch
            checked={preferences?.rotateViewByOrientation || false}
            color="primary"
            onChange={handleChangeSwitch('rotateViewByOrientation')}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

GeneralPreferences.propTypes = {
  className: PropTypes.string,
  onUpdatePreferences: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
};

export default GeneralPreferences;
