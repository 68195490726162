import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AddOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FORM_PATH, FORM_TYPENAME } from '@consts';
import IconFormProgress from './IconFormProgress';
import EditFunclocTriggers from '@components/Funcloc/EditFunclocTriggers';
import { i18n } from '@geomagic/i18n';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';
import getFunclocTypeName from './utils/getFunclocTypeName';

const typographyProps = {
  noWrap: true,
};

const PageSelect = (props) => {
  const {
    entityClasses,
    forms,
    isMarkdown,
    isReadOnly,
    onChange,
    onEditNewFuncloc,
    onRemoveFuncloc,
    onUpdateForm,
    path,
    secondaryAction,
    templateForms,
    title,
    value,
  } = props;
  const activeForm = forms?.[value];
  const hasPages = forms.length + templateForms.length > 0;
  const newFormIndex = forms.length + templateForms.length;
  const { description, fromTemplate, name, optional, omitted } = activeForm;

  const toggleActionDrawer = useToggleActionDrawer();

  /**
   *  EVENT HANDLER
   */
  const pages = forms.map((form, index) => ({
    id: form.id,
    label: (
      <Box sx={{ alignItems: 'center', display: 'flex', listStyle: 'none' }}>
        <IconFormProgress forms={[form]} omitted={form.omitted} optional={form.optional} />
        <Typography {...typographyProps}>{form.name}</Typography>
      </Box>
    ),
    onClick: (event) => onChange(event, index),
  }));

  const templatePages = templateForms.map((form, index) => ({
    id: form.id,
    label: (
      <Box sx={{ alignItems: 'center', display: 'flex', listStyle: 'none' }}>
        <AddOutlineIcon sx={{ marginRight: 2, minHeight: 40, width: 16 }} />
        <Typography {...typographyProps}>
          {i18n.t(`funcloc.button.add`, {
            variables: {
              name: getFunclocTypeName(entityClasses, form.funclocTypeId),
            },
          })}
        </Typography>
      </Box>
    ),
    onClick: (event) =>
      onEditNewFuncloc({
        template: { ...form, typename: FORM_TYPENAME },
        parentPath: `${FORM_PATH}`,
        path: `${FORM_PATH}/${newFormIndex}`,
      }),
  }));

  const handleClick = () => {
    toggleActionDrawer({
      title,
      items: [...pages, ...templatePages],
    });
  };

  const handleOmit = (event) => {
    event.stopPropagation();
    onUpdateForm({ omitted: !omitted, typename: FORM_TYPENAME }, path, activeForm);
  };

  const handleRemovePage = () => {
    onRemoveFuncloc({ path, removeId: activeForm.id });
    onChange(null, 0);
  };

  const handleEditNewFuncloc = () => {
    onEditNewFuncloc({ parentPath: `${FORM_PATH}`, path });
  };

  return (
    <ListItem sx={{ alignItems: 'center', display: 'flex', listStyle: 'none' }} disableGutters>
      <IconFormProgress
        forms={[activeForm]}
        onClick={optional && !isReadOnly ? handleOmit : null}
        omitted={omitted}
        optional={optional}
      />
      <ListItemButton disableGutters onClick={hasPages && handleClick}>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ ...typographyProps, sx: { fontWeight: 500 }, variant: 'h5' }}
          secondary={!isMarkdown ? description : ''}
          secondaryTypographyProps={typographyProps}
        />
        {hasPages && <KeyboardArrowDownIcon />}
      </ListItemButton>
      {fromTemplate && (
        <EditFunclocTriggers onEditNewFuncloc={handleEditNewFuncloc} onDeleteFuncloc={handleRemovePage} />
      )}
      {secondaryAction && secondaryAction}
    </ListItem>
  );
};

PageSelect.propTypes = {
  entityClasses: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  isMarkdown: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEditNewFuncloc: PropTypes.func.isRequired,
  onRemoveFuncloc: PropTypes.func.isRequired,
  onUpdateForm: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  secondaryAction: PropTypes.node,
  templateForms: PropTypes.array.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default PageSelect;
