import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import Congratulations from './Congratulations';

export const CongratulationsContext = createContext();

const ANIMATION_TIME = 2;

const CongratulationsProvider = (props) => {
  const { children } = props;
  const [isAnimationVisible, setIsAnimationVisible] = useState(false);

  const executeFuncAfterAnimation = async (delayedFunc) => {
    const asyncSetIsAnimationScreen = () =>
      new Promise((resolve) => {
        setIsAnimationVisible(true);
        return resolve();
      });
    const asyncFunc = () =>
      new Promise((resolve) =>
        setTimeout(() => {
          delayedFunc();
          setIsAnimationVisible(false);
          return resolve();
        }, ANIMATION_TIME * 1000)
      );

    return await Promise.all([asyncSetIsAnimationScreen(), asyncFunc()]);
  };

  return (
    <CongratulationsContext.Provider value={{ isAnimationVisible, setIsAnimationVisible, executeFuncAfterAnimation }}>
      {children}
      <Congratulations isAnimationVisible={isAnimationVisible} />
    </CongratulationsContext.Provider>
  );
};

CongratulationsProvider.propTypes = {
  children: PropTypes.node,
};

export default CongratulationsProvider;
