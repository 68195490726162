import isObject from 'lodash/isObject';
import { i18n } from '@geomagic/i18n';
import fetchUrl from './fetchUrl';
import { BASE_KEY } from '../consts';

const getTileUrl = async (baseUrl) => {
  let baseFetchResult;
  const urlsToCache = [];
  const cache = await window.caches.open(BASE_KEY);

  try {
    if (baseUrl) {
      baseFetchResult = await fetchUrl(baseUrl);
    }
  } catch (error) {
    throw new Error(i18n.t('offlineMap.notification.fetchError'));
  }

  if (baseFetchResult) {
    const { json: baseJson, response: baseResponse } = baseFetchResult;
    urlsToCache.push({ url: baseUrl, response: baseResponse });

    if (isObject(baseJson?.sources)) {
      const sources = baseJson.sources;
      const source = Object.keys(sources)[0];
      const { url: sourceUrl } = sources[source] || {};

      if (sourceUrl) {
        const fetchResult = await fetchUrl(sourceUrl);

        if (fetchResult) {
          const { json: dataJson, response: sourceResponse } = fetchResult;
          const clonedResponse = sourceResponse.clone();

          urlsToCache.push({ url: sourceUrl, response: clonedResponse });
          const cacheToPut = urlsToCache.map(({ url, response }) => cache.put(url, response));

          await Promise.all([...cacheToPut]);

          return dataJson?.tiles?.[0];
        }
      }
    }
  }
};

export default getTileUrl;
