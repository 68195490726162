import { transformExtent } from 'ol/proj';
import { BASE_EXTENT, MAX_BASE_TILE_ZOOM_LEVEL, MIN_BASE_TILE_ZOOM_LEVEL } from '../consts';
import getVectorTileUrls from './getVectorTileUrls';
import addToCache from './addToCache';

const handleFetchBaseTiles = async (key, url, srid) => {
  const { epsgCode, extent } = BASE_EXTENT;
  const transformedExtent = transformExtent(extent, epsgCode, `EPSG:${srid || 3857}`);

  const urls = getVectorTileUrls({
    extent: transformedExtent,
    minZoomLevel: MIN_BASE_TILE_ZOOM_LEVEL,
    maxZoomLevel: MAX_BASE_TILE_ZOOM_LEVEL,
    url,
  });

  await addToCache(key, urls);
};

export default handleFetchBaseTiles;
