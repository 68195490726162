import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Switch from '@mui/material/Switch';
import BlockIcon from '@mui/icons-material/Block';
import DispatchIcon from '@geomagic/nam-react-core/icons/Dispatch';
import hasChildrenWithDefectStatus from './utils/hasChildrenWithDefectStatus';
import getFormProgress from './utils/getFormProgress';

const AVATAR_ICON_SIZE = 16;
const VARIANT_ICON_SIZE = 16;
const VARIANT_ICON_THICKNESS = 2;

const FormIconProgress = (props) => {
  const { forms = [], onClick, omitted, optional } = props;

  const hasDispatches = hasChildrenWithDefectStatus(forms);

  const { isSuccess, isWarning, total } = getFormProgress(forms);
  const isSwitch = optional && onClick;

  if (total < 1 && !optional) return null;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        height: '100%',
        marginRight: 1,
        justifyContent: 'flex-end',
      }}
    >
      {isSwitch ? (
        <Switch
          sx={{
            pointerEvents: 'auto',
          }}
          color={!omitted ? (isSuccess ? 'success' : 'warning') : 'default'}
          onFocus={(event) => event.stopPropagation()}
          size="small"
          checked={!omitted}
          onMouseDown={onClick}
        />
      ) : omitted ? (
        <BlockIcon
          sx={{
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: VARIANT_ICON_THICKNESS,
            minHeight: VARIANT_ICON_SIZE,
            minWidth: VARIANT_ICON_SIZE,
            marginRight: 2,
          }}
        />
      ) : (
        <Box
          sx={{
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: VARIANT_ICON_THICKNESS,
            minHeight: VARIANT_ICON_SIZE,
            minWidth: VARIANT_ICON_SIZE,
            marginRight: 2,
            ...(isSuccess && {
              backgroundColor: 'success.main',
              borderColor: 'success.dark',
            }),
            ...(isWarning && {
              backgroundColor: 'warning.main',
              borderColor: 'warning.dark',
            }),
          }}
        />
      )}
      {hasDispatches && (
        <SvgIcon
          color="primary"
          sx={{
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'background.paper',
            borderRadius: '50%',
            position: 'absolute',
            height: `${AVATAR_ICON_SIZE}px`,
            width: `${AVATAR_ICON_SIZE}px`,
            bottom: (theme) => theme.spacing(-1),
            right: (theme) => theme.spacing(1),
            ...(isSwitch && {
              bottom: (theme) => theme.spacing(-0.5),
              right: (theme) => theme.spacing(-0.5),
            }),
          }}
        >
          <DispatchIcon />
        </SvgIcon>
      )}
    </Box>
  );
};

FormIconProgress.propTypes = {
  forms: PropTypes.array,
  onClick: PropTypes.func,
  omitted: PropTypes.bool,
  optional: PropTypes.bool,
};

export default FormIconProgress;
